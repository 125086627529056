import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { createCompSchemaValidator } from '@wix/editor-elements-corvid-utils';

type ChangeEventHandler = (e: React.ChangeEvent) => void;

export interface IChangePropsSDKProps {}

export interface IChangePropsSDK {
  onChange: (handler: ChangeEventHandler) => void;
}

export interface IChangePropsSDKActions<T = Element> {
  onChange?: (event: React.ChangeEvent<T>) => void;
}

export const changePropsSDKFactory: CorvidSDKPropsFactory<
  IChangePropsSDKProps,
  IChangePropsSDK
> = ({ registerEvent, metaData, getSdkInstance }) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );

  return {
    onChange(handler: ChangeEventHandler) {
      if (!functionValidator(handler, 'onChange')) {
        return getSdkInstance();
      }

      registerEvent('onChange', handler);
      return getSdkInstance();
    },
  };
};
