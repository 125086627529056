import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  childrenPropsSDKFactory,
  elementPropsSDKFactory,
  toJSONBase,
  viewportPropsSDKFactory,
} from '../../../core/corvid/props-factories';
import {
  SlideShowSlideProps,
  ISlideShowSlideSDK,
  ISlideShowSlideOwnSDKFactory,
} from '../SlideShowSlide.types';
import { backgroundPropsSDKFactory } from '../../../core/corvid/props-factories/backgroundPropsSDKFactory';

const _ownSDKFactory: ISlideShowSlideOwnSDKFactory = ({
  sdkData,
  getSdkInstance,
  metaData,
}) => ({
  get type() {
    return '$w.Slide';
  },
  get name() {
    return sdkData.name;
  },
  toJSON() {
    const sdkInstance = getSdkInstance();
    return {
      ...toJSONBase(metaData),
      type: sdkInstance.type,
      name: sdkData.name,
      background: sdkInstance.background.src,
    };
  },
});

export const sdk = composeSDKFactories<SlideShowSlideProps, ISlideShowSlideSDK>(
  elementPropsSDKFactory,
  viewportPropsSDKFactory,
  childrenPropsSDKFactory,
  backgroundPropsSDKFactory,
  _ownSDKFactory,
);
