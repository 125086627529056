import { parseMediaSrc } from '../../core/corvid/media/mediaSrcHandler';
import { GridImageData, GridRow } from './Grid.types';

type DataPath = string | Array<string>;

export const extractImageDataFromSrc = (src: string): GridImageData | null => {
  const { error, title, mediaId: uri, width, height } = parseMediaSrc(
    src,
    'image',
  );

  if (error || !uri) {
    return null;
  }

  return { uri, width, height, title, name: title };
};

export const getRowValue = (row: GridRow, dataPath: DataPath): any => {
  const dataPathParts = Array.isArray(dataPath)
    ? dataPath
    : dataPath.split('.');

  let value = row;
  let index = 0;

  while (value && index < dataPathParts.length) {
    value = value[dataPathParts[index++]];
  }

  return index && index === dataPathParts.length ? value : undefined;
};

export const setRowValue = (row: GridRow, dataPath: DataPath, value: any) => {
  const dataPathParts = Array.isArray(dataPath)
    ? dataPath
    : dataPath.split('.');

  let valueRef = row;
  let index = 0;

  while (valueRef && index < dataPathParts.length - 1) {
    valueRef = valueRef[dataPathParts[index++]];
  }

  valueRef[dataPathParts[index]] = value;
};
