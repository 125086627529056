import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';

export interface IHiddenPropSDK {
  hide: () => Promise<void>;
  show: () => Promise<void>;
  hidden: boolean;
  isVisible: boolean;
  isAnimatable: boolean;
}

export const hiddenPropsSDKFactory: CorvidSDKPropsFactory<
  {},
  IHiddenPropSDK
> = ({ setStyles, metaData, getSdkInstance }) => {
  const _state = {
    hidden: metaData.hiddenOnLoad,
  };
  return {
    hide: () => {
      setStyles({
        visibility: 'hidden',
      });
      _state.hidden = true;
      return Promise.resolve();
    },
    show: () => {
      setStyles({
        visibility: 'visible',
      });
      _state.hidden = false;
      return Promise.resolve();
    },
    get hidden() {
      return Boolean(_state.hidden);
    },

    get isVisible() {
      let parentSdk = getSdkInstance();
      while (parentSdk) {
        if (parentSdk.rendered || parentSdk.hidden || parentSdk.collapsed) {
          return false;
        }
        parentSdk = parentSdk.parent;
      }
      return true;
    },

    get isAnimatable() {
      return true;
    },
  };
};
