import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  createStylePropsSDKFactory,
  disablePropsSDKFactory,
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  labelPropsSDKFactory,
  linkPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  IStylableButtonOwnSDKFactory,
  IStylableButtonProps,
  IStylableButtonSDK,
} from '../StylableButton.types';

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: { withoutDefaultValue: true, supportOpacity: false },
  BorderColor: { withoutDefaultValue: true, supportOpacity: false },
  BorderRadius: { withoutDefaultValue: true },
  BorderWidth: { withoutDefaultValue: true },
  TextColor: { withoutDefaultValue: true },
});

const _stylableButtonSDKFactory: IStylableButtonOwnSDKFactory = api => {
  const labelSDK = labelPropsSDKFactory(api);
  const styleSDK = stylePropsSDKFactory(api);

  return {
    get label() {
      return labelSDK.label;
    },
    set label(value) {
      labelSDK.label = value;
    },
    get style() {
      return {
        get backgroundColor() {
          return styleSDK.style.backgroundColor;
        },
        set backgroundColor(backgroundColor: string | undefined) {
          styleSDK.style.backgroundColor = backgroundColor;
          api.setProps({
            corvid: { ...api.props.corvid, hasBackgroundColor: true },
          });
        },
        get borderColor() {
          return styleSDK.style.borderColor;
        },
        set borderColor(borderColor: string | undefined) {
          styleSDK.style.borderColor = borderColor;
          api.setProps({
            corvid: { ...api.props.corvid, hasBorderColor: true },
          });
        },
        get borderRadius() {
          return styleSDK.style.borderRadius;
        },
        set borderRadius(borderRadius: string | undefined) {
          styleSDK.style.borderRadius = borderRadius;
          api.setProps({
            corvid: { ...api.props.corvid, hasBorderRadius: true },
          });
        },
        get borderWidth() {
          return styleSDK.style.borderWidth;
        },
        set borderWidth(borderWidth: string | undefined) {
          styleSDK.style.borderWidth = borderWidth;
          api.setProps({
            corvid: { ...api.props.corvid, hasBorderWidth: true },
          });
        },
        get color() {
          return styleSDK.style.color;
        },
        set color(color: string | undefined) {
          styleSDK.style.color = color;
          api.setProps({
            corvid: { ...api.props.corvid, hasColor: true },
          });
        },
      };
    },

    toJSON() {
      const { label } = labelSDK;
      const { style } = styleSDK;

      return {
        ...toJSONBase(api.metaData),
        label,
        style: { ...style },
      };
    },
  };
};

export const sdk = composeSDKFactories<
  IStylableButtonProps,
  IStylableButtonSDK
>(
  elementPropsSDKFactory,
  clickPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  linkPropsSDKFactory,
  collapsedPropsSDKFactory,
  _stylableButtonSDKFactory,
);
