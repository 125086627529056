import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { createCompSchemaValidator } from '@wix/editor-elements-corvid-utils';
import { MouseEventHandler } from 'react';

export interface IClickPropSDKProps {}

export interface IClickPropWithUpdateHandlerSDKProps
  extends IClickPropSDKProps {
  hasPlatformClickHandler: boolean;
}
export interface IClickPropSDK {
  onClick: (handler: MouseEventHandler) => void;
  onDblClick: (handler: MouseEventHandler) => void;
}

export interface IClickPropsSDKActions {
  onClick: MouseEventHandler;
  onDblClick: MouseEventHandler;
}

export const clickPropsSDKFactoryWithUpdatePlatformHandler: CorvidSDKPropsFactory<
  IClickPropWithUpdateHandlerSDKProps,
  IClickPropSDK
> = api => {
  const clickPropsApi = clickPropsSDKFactory(api);
  const { setProps, props } = api;

  return {
    ...clickPropsApi,
    onClick: (handler: MouseEventHandler) => {
      clickPropsApi.onClick(handler);
      if (!props.hasPlatformClickHandler) {
        setProps({
          hasPlatformClickHandler: true,
        });
      }
    },
  };
};

export const clickPropsSDKFactory: CorvidSDKPropsFactory<
  IClickPropSDKProps,
  IClickPropSDK
> = ({ registerEvent, metaData, getSdkInstance }) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );

  return {
    onClick(handler: MouseEventHandler) {
      if (!functionValidator(handler, 'onClick')) {
        return getSdkInstance();
      }
      registerEvent('onClick', handler);
      return getSdkInstance();
    },
    onDblClick(handler: MouseEventHandler) {
      if (!functionValidator(handler, 'onDblClick')) {
        return getSdkInstance();
      }
      registerEvent('onDblClick', handler);
      return getSdkInstance();
    },
  };
};
