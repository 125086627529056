import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { IContainerSDK, IContainerProps } from '../Container.types';
import {
  elementPropsSDKFactory,
  createStylePropsSDKFactory,
  childrenPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
} from '../../../core/corvid/props-factories';

const stylePropsSDKFactory = createStylePropsSDKFactory(
  {
    BackgroundColor: true,
    BorderColor: true,
    BorderWidth: true,
  },
  {
    cssVarPrefix: 'container',
  },
);

export const sdk = composeSDKFactories<IContainerProps, IContainerSDK, any>(
  elementPropsSDKFactory, // TODO - pass onMouseEnter and onMouseLeave
  stylePropsSDKFactory,
  childrenPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  clickPropsSDKFactory,
);
