import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { EventHandler } from 'react';
import { createCompSchemaValidator } from '@wix/editor-elements-corvid-utils';

export interface IViewportPropSDK {
  onViewportEnter(cb: EventHandler<any>): void;
  onViewportLeave(cb: EventHandler<any>): void;
}

export const viewportPropsSDKFactory: CorvidSDKPropsFactory<
  {},
  IViewportPropSDK
> = ({ handlers, metaData, getSdkInstance }) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  return {
    onViewportEnter: (cb: EventHandler<any>) => {
      if (!functionValidator(cb, 'onViewportEnter')) {
        return getSdkInstance();
      }
      handlers.onViewportEnter(metaData.compId, cb);
      return getSdkInstance();
    },

    onViewportLeave: (cb: EventHandler<any>) => {
      if (!functionValidator(cb, 'onViewportLeave')) {
        return getSdkInstance();
      }

      handlers.onViewportLeave(metaData.compId, cb);

      return getSdkInstance();
    },
  };
};
