import {
  withValidation,
  assert,
  composeSDKFactories,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import { IMusicPlayerSDKFactory } from '../MusicPlayer.types';
import { isValidMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';
import { elementPropsSDKFactory } from '../../../core/corvid/props-factories/elementPropsSDKFactory';

const _musicPlayerSDKFactory: IMusicPlayerSDKFactory = ({
  setProps,
  props,
  registerEvent,
  compRef,
  metaData,
  getSdkInstance,
}) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  return {
    get artistName() {
      return props.playlist[0].artistName;
    },
    set artistName(value) {
      setProps({ playlist: [{ ...props.playlist[0], artistName: value }] });
    },
    get trackName() {
      return props.playlist[0].trackName;
    },
    set trackName(value) {
      setProps({ playlist: [{ ...props.playlist[0], trackName: value }] });
    },
    get coverImage() {
      return props.playlist[0].cover;
    },
    set coverImage(value) {
      const cover = assert.isNil(value) ? '' : value;
      if (isValidMediaSrc(cover, 'image')) {
        setProps({ playlist: [{ ...props.playlist[0], cover }] });
      }
    },
    get src() {
      return props.playlist[0].url;
    },
    set src(value) {
      const url = assert.isNil(value) ? '' : value;
      if (isValidMediaSrc(url, 'audio')) {
        setProps({ playlist: [{ ...props.playlist[0], url }] });
      }
    },

    get currentTime() {
      return props.currentTime;
    },

    get duration() {
      return props.duration;
    },

    get volume() {
      return props.volume;
    },

    set volume(value) {
      if (value) {
        setProps({ volume: value / 100 });
      }
    },

    get isMuted() {
      return props.muted;
    },

    get isPlaying() {
      return props.isPlaying;
    },
    mute() {
      setProps({ muted: true });
    },
    unmute() {
      setProps({ muted: false });
    },
    seek(time) {
      compRef.seek(time);
    },
    play() {
      compRef.play();
    },
    pause() {
      compRef.pause();
    },
    stop() {
      compRef.pause();
      compRef.seek(0);
    },
    togglePlay() {
      compRef.togglePlay();
    },
    onPlay(handler: () => void) {
      if (!functionValidator(handler, 'onPlay')) {
        return getSdkInstance();
      }
      registerEvent('onPlay', handler);
      return getSdkInstance();
    },
    onPause(handler: () => void) {
      if (!functionValidator(handler, 'onPause')) {
        return getSdkInstance();
      }
      registerEvent('onPause', handler);
      return getSdkInstance();
    },
    onEnded(handler: () => void) {
      if (!functionValidator(handler, 'onEnded')) {
        return getSdkInstance();
      }
      registerEvent('onEnded', handler);
      return getSdkInstance();
    },
    onProgress(handler: () => void) {
      if (!functionValidator(handler, 'onProgress')) {
        return getSdkInstance();
      }
      registerEvent('onTimeUpdated', handler);
      return getSdkInstance();
    },
  };
};

const musicPlayerSDKFactory: IMusicPlayerSDKFactory = withValidation(
  _musicPlayerSDKFactory,
  {
    type: ['object'],
    properties: {
      artistName: { type: ['string', 'nil'], warnIfNil: true },
      trackName: { type: ['string', 'nil'], warnIfNil: true },
      coverImage: { type: ['string', 'nil'], warnIfNil: true },
      src: { type: ['string', 'nil'], warnIfNil: true },
      currentTime: { type: ['number', 'nil'], warnIfNil: true },
      duration: { type: ['number', 'nil'], warnIfNil: true },
      volume: {
        type: ['number', 'nil'],
        minimum: 0,
        maximum: 100,
        warnIfNil: true,
      },
      seek: { type: ['function'], args: [{ type: ['number'] }] },
    },
  },
);

export const sdk: IMusicPlayerSDKFactory = composeSDKFactories(
  elementPropsSDKFactory,
  musicPlayerSDKFactory,
);
